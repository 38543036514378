import { Controller } from "stimulus"
import $ from "jquery"
import { markLoading, removeLoading } from "mz_ui/utils/mark_loading"
import { stopEverything } from "@rails/ujs"
import { Modal } from 'bootstrap'

export default class extends Controller {
  static targets = [ 'modal', 'content' ]

  disconnect() {
    if (this.modal) {
      this.modal.dispose();
    }
  }

  loadModal(event) {
    let url;
    if ((event != null) && event.detail && event.detail.url) {
      ({
        url
      } = event.detail);
      this.data.set('url', url);
    } else if ((event != null) && event.target && event.target.dataset.modalLoaderUrl) {
      url = event.target.dataset.modalLoaderUrl;
      this.data.set('url', url);
    } else if ((event != null) && event.target && event.target.href) {
      url = event.target.href;
      this.data.set('url', url);
    } else if ((event != null) && event.currentTarget && event.currentTarget.dataset.modalLoaderUrl) {
      url = event.currentTarget.dataset.modalLoaderUrl;
      this.data.set('url', url);
    } else if ((event != null) && event.currentTarget && event.currentTarget.href) {
      url = event.currentTarget.href;
      this.data.set('url', url);
    } else if (this.data.has('url')) {
      url = this.data.get('url');
    }

    this.modal = new Modal(this.modalTarget);
    this.modal.show();

    if (url != null) {
      const ct = this.contentTarget;
      markLoading(ct);
      $(ct).load(url, () => removeLoading(ct));
    }

    stopEverything(event);
  }

  closeModal() {
    this.modal.hide();
  }
}
