import { Controller } from "stimulus"
import * as echarts from 'echarts';

import { formatNumber } from 'number-format';

import $ from "jquery"

export default class extends Controller {
  connect() {

    let labels = JSON.parse(this.data.get('labels')) || []
    let colors = JSON.parse(this.data.get('colors')) || []
    let data = JSON.parse(this.data.get('data')) || []
    let title = this.data.get('title')
    let stacked = this.data.get('stacked')
    let trendline = this.data.get('trendline')
    let yType = this.data.get('yType') || 'value'
    let datasetLabels = JSON.parse(this.data.get('datasetLabels')) || []
    let skip_zoom_slider = this.data.get('skipZoomSlider') || false
    let rotate = this.data.get('rotate') || 30
    let save_as_image = this.data.get('saveAsImage') || false
    let color_by_data = this.data.get('colorByData') || false

    let min = yType == 'log' ? 1 : undefined

    let datasets = []

    data.forEach((el, ix) => {
      let ds = {
        name: datasetLabels[ix],
        type: 'bar',
        color: colors[ix],
        data: el,
        // label: {
        //   show: true,
        //   color: 'black',
        //   rotate: 90,
        //   formatter: (params) => params.name
        // },
        tooltip: {
          valueFormatter: formatNumber
        },
      }
      if (color_by_data) {
        ds.colorBy = 'data'
      }

      datasets.push(ds)
    })

    this.chart = echarts.init(this.element)

    let opts = {
      grid: {
        // top: 100,
        left: '3%',
        right: '1%',
        bottom: '1%',
        containLabel: true
      },
      legend: {
        top: 50
      },
      title: {
        text: title
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      xAxis: {
        data: labels,
        type: 'category',
        axisTick: {
          alignWithLabel: true
        },
        axisLabel: {
          rotate: rotate
        },
      },
      toolbox: {
        feature: {
          saveAsImage: save_as_image ? { name: title, title: 'Сохранить изображение графика' } : false
        }
      },
      yAxis: {
        type: yType,
        min: min,
        axisLabel: {
          formatter: formatNumber
        },
        axisPointer: {
          label: {
            formatter: formatNumber
          }
        }
      },
      series: datasets,
      dataZoom: [
        {
          type: 'slider',
          top: 'top',
          show: !skip_zoom_slider
        }
      ],
    }

    this.chart.setOption(opts)
  }

  disconnect() {
    this.chart.dispose()
  }

  layout() {
    this.chart.resize()
  }
}
