import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex'
import $ from 'jquery'

export default class extends Controller {
  static targets = [ "otherLabel", "fire", "flood", "humanDiseases", "animalDiseases", "plantDiseases", "otherSourceLabel" ]

  connect() {
    StimulusReflex.register(this)

    this.toggleOtherLabel(
      this.data.has('voc-situation-ids') && (
        this.data.get('voc-situation-ids').indexOf('12') >= 0
      )
    )

    this.toggleOtherSourceLabel(
      this.data.has('voc-info-source-id') && (
        this.data.get('voc-info-source-id') === '35'
      )
    )

    // this.toggleDangerFields(
    //   this.data.has('voc-situation-ids') && (
    //     this.data.get('voc-situation-ids').indexOf('11') >= 0
    //   )
    // )

    this.toggleHumanDiseases(
      this.data.has('voc-situation-type-id') && (
        this.data.get('voc-situation-type-id') === '1'
      )
    )

    this.toggleAnimalDiseases(
      this.data.has('voc-situation-type-id') && (
        this.data.get('voc-situation-type-id') === '2'
      )
    )

    this.togglePlantDiseases(
      this.data.has('voc-situation-type-id') && (
        this.data.get('voc-situation-type-id') === '3'
      )
    )
  }

  doSituationSelect(event) {
    const situation_ids = [];
    $(event.currentTarget).find("option:selected").each(function(i, selected){
      situation_ids.push($(selected).val());
    });

    this.toggleOtherLabel((situation_ids.indexOf('12') >= 0))
    // this.toggleDangerFields((situation_ids.indexOf('11') >= 0))
  }

  doTypeSelect(event) {
    this.toggleOtherLabel(false)
    // this.toggleDangerFields(false)

    let id = $(event.target).val()
    this.toggleHumanDiseases(id === '1')
    this.toggleAnimalDiseases(id === '2')
    this.togglePlantDiseases(id === '3')

    // if (!id) { return }

    $('#incidents-cards-voc-situations-details select').attr('data-controller', '')
    this.stimulate('Incidents::Card#situations_details', { serializeForm: false }, id)
  }

  doInfoSourceSelect(event) {
    const source_id = event.target.value
    this.toggleOtherSourceLabel(source_id === '35')
  }


  toggleOtherLabel(isOther) {
    if (!this.hasOtherLabelTarget) { return }

    if (isOther) {
      $(this.otherLabelTarget).show()
    } else {
      $(this.otherLabelTarget).hide()
    }
  }

  toggleOtherSourceLabel(isOther) {
    if (!this.hasOtherSourceLabelTarget) { return }

    if (isOther) {
      $(this.otherSourceLabelTarget).show()
    } else {
      $(this.otherSourceLabelTarget).hide()
    }
  }

  // toggleDangerFields(isDanger) {
  //   if (!this.hasFireTarget && !this.hasFloofTarget) { return }
  //
  //   if (isDanger) {
  //     $(this.fireTarget).show()
  //     $(this.floodTarget).show()
  //   } else {
  //     $(this.fireTarget).hide()
  //     $(this.floodTarget).hide()
  //   }
  // }

  toggleHumanDiseases(isHuman) {
    if (!this.hasHumanDiseasesTarget) { return }

    if (isHuman) {
      $(this.humanDiseasesTarget).show()
    } else {
      $(this.humanDiseasesTarget).hide()
    }
  }

  toggleAnimalDiseases(isAnimal) {
    if (!this.hasAnimalDiseasesTarget) { return }

    if (isAnimal) {
      $(this.animalDiseasesTarget).show()
    } else {
      $(this.animalDiseasesTarget).hide()
    }
  }

  togglePlantDiseases(isPlant) {
    if (!this.hasPlantDiseasesTarget) { return }

    if (isPlant) {
      $(this.plantDiseasesTarget).show()
    } else {
      $(this.plantDiseasesTarget).hide()
    }
  }
}
