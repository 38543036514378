import { Controller } from "stimulus"
import $ from "jquery"

export default class extends Controller {
  static targets = ['changeAll', 'multipleCheckboxes']


  doChangeAllCheckboxes(event) {

    console.log($(this.changeAllTarget).prop('checked'))
    if ($(this.changeAllTarget).prop('checked')) {
      $(this.multipleCheckboxesTargets).prop('checked', true)
    } else {
      $(this.multipleCheckboxesTargets).prop('checked', false)
    }
  }
}
