import $ from 'jquery'

const getCellAt = function (cellsMatrix, x, y) {
  var cell = null;

  if (cellsMatrix[y] && cellsMatrix[y][x]) {
    return cellsMatrix[y][x];
  }

  return cell;
}

const scanTable = function (table) {
  let $table = $(table);
  let m = [];
  $table.find("tr, thead > tr, tbody > tr, tfoot > tr").each(function (y, row) {
    $(row).children("td, th").each(function (x, cell) {
      var $cell = $(cell),
        cspan = $cell.attr("colspan") | 0,
        rspan = $cell.attr("rowspan") | 0,
        tx, ty;
      cspan = cspan ? cspan : 1;
      rspan = rspan ? rspan : 1;
      for (; m[y] && m[y][x]; ++x) ;  //skip already occupied cells in current row
      for (tx = x; tx < x + cspan; ++tx) {  //mark matrix elements occupied by current cell with true
        for (ty = y; ty < y + rspan; ++ty) {
          if (!m[ty]) {  //fill missing rows
            m[ty] = [];
          }
          m[ty][tx] = $cell;
        }
      }
      var pos = {top: y, left: x};
      $cell.data("cellPos", pos);
    });
  });

  return m;
}

export { getCellAt, scanTable }
