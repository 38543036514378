import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex'
import $ from 'jquery'

export default class extends Controller {
  static targets = [ "riskLevel", "scoreFields", "diseaseFirstScore", "diseaseOtherScore",
    "deathsFirstScore", "deathsOtherScore"]

  connect() {
    StimulusReflex.register(this)

    this.toggleScoreFields(
      this.data.has('evaluation-kind') && (
        this.data.get('evaluation-kind') === 'calculation'
      )
    )

    this.toggleFirstScore(
      this.data.has('pathogenicity-score') && (
        this.data.get('pathogenicity-score') === '3'
      )
    )

    this.toggleOtherScore(
      this.data.has('pathogenicity-score') && (
        this.data.get('pathogenicity-score') != '3' && this.data.get('pathogenicity-score') != ''
      )
    )
  }

  doEvaluationChange(event) {
    const val = event.target.value
    this.toggleRiskLevel(val === 'expert')
    this.toggleScoreFields(val === 'calculation')
  }

  doPathogenicityChange(event) {
    const val = event.target.value
    this.toggleFirstScore(val === '3')
    this.toggleOtherScore(val != '3')
  }

  toggleRiskLevel(show) {
    if (!this.hasRiskLevelTarget) { return }

    if (show) {
      $(this.riskLevelTarget).removeClass('d-none')
    } else {
      $(this.riskLevelTarget).addClass('d-none')
    }
  }

  toggleScoreFields(show) {
    if (!this.hasScoreFieldsTarget) { return }

    if (show) {
      $(this.scoreFieldsTarget).show()
    } else {
      $(this.scoreFieldsTarget).hide()
    }
  }

  toggleFirstScore(isFirst) {
    if (!this.hasDiseaseFirstScoreTarget && !this.hasDeathsFirstScoreTarget) { return }

    if (isFirst) {
      $(this.deathsFirstScoreTarget).show()
      $(this.diseaseFirstScoreTarget).show()
    } else {
      $(this.deathsFirstScoreTarget).hide()
      $(this.diseaseFirstScoreTarget).hide()
    }
  }

  toggleOtherScore(isOther) {
    if (!this.hasDiseaseOtherScoreTarget && !this.hasDeathsOtherScoreTarget) { return }

    if (isOther) {
      $(this.deathsOtherScoreTarget).show()
      $(this.diseaseOtherScoreTarget).show()
    } else {
      $(this.deathsOtherScoreTarget).hide()
      $(this.diseaseOtherScoreTarget).hide()
    }
  }
}
