import { Controller } from "stimulus"
import * as echarts from 'echarts'
import 'echarts-gl'
import { geoConicConformal } from 'd3-geo'

import * as topojson from 'topojson-client'
import russia from '../russia.topo.json'
import russia3dgeo from '../russia3d.geo.json'
import ruRectSVG from '../ru-rect.svg.geo'
import ruHexSVG from '../ru-hex.svg.geo'

import regression from 'regression'
import { round, determinationCoefficient } from 'math-utils'
import { formatNumber } from 'number-format'

export default class extends Controller {
  connect() {
    let info = JSON.parse(this.data.get('info')) || []
    let palette = JSON.parse(this.data.get('colors')) || undefined

    let predict = this.data.get('predict')

    let geoType = this.data.get('geoType') || 'map'

    if (predict) {
      info.forEach((el) => {
        if (el.values) {
          let regr = regression['linear'](el.values)

          el.value = round(regr.predict(predict)[1], 0)
          if (el.value < 0) { el.value = 0 }
          el.r2 = regr.r2 || round(determinationCoefficient(el.values, regr.points), 2)
        }
      })
    }

    let minValue = Infinity
    let maxValue = -Infinity
    info.forEach((el) => {
      if (el.value > maxValue) {
        maxValue = el.value
      }
      if (el.value < minValue) {
        minValue = el.value
      }
    })

    // https://bl.ocks.org/mbostock/4613663
    // Asia Lambert Conic Conformal
    // (ref http://spatialreference.org/ref/esri/102012/)
    let geoProjection = geoConicConformal()
      .scale(600)
      .rotate([-105, 0])
      .center([0, 65])
      .parallels([30, 62])

    let projection = {
      project: (point) => geoProjection(point),
      unproject: (point) => geoProjection.invert(point)
    }

    let russiaProj = topojson.feature(russia, russia.objects.default)

    echarts.registerMap('RU', russiaProj)
    echarts.registerMap('RU3D', russia3dgeo)
    echarts.registerMap('RUrect', {svg: ruRectSVG})
    echarts.registerMap('RUhex', {svg: ruHexSVG})

    let geo3D;
    let serie;

    let map = 'RU'

    if (geoType === 'hex') {
      map = 'RUhex'
      projection = undefined
    } else if (geoType === 'rect') {
      map = 'RUrect'
      projection = undefined
    } else if (geoType === 'map3d') {
      map = 'RU3D'
      projection = undefined
      geo3D = {
        map: 'RU3D',
          shading: 'lambert',
          light: {
          main: {
            intensity: 5,
              shadow: true,
              shadowQuality: 'high',
              alpha: 30
          },
          ambient: {
            intensity: 0
          },
        },
        viewControl: {
          distance: 50,
            panMouseButton: 'left',
            rotateMouseButton: 'right'
        },
        groundPlane: {
          show: false,
            color: '#fff'
        },
        postEffect: {
          enable: true,
            bloom: {
            enable: false
          },
          SSAO: {
            radius: 1,
              intensity: 1,
              enable: true
          },
          depthOfField: {
            enable: false,
              focalRange: 10,
              blurRadius: 10,
              fstop: 1
          }
        },
        temporalSuperSampling: {
          enable: true
        },
        itemStyle: {
          borderColor: '#777',
          borderWidth: 1,
          color: '#ff7'
        },
        regionHeight: 0.5
      }
    }


    if (geoType === 'map3d') {
      let info3d = []
      let infoIX = {}
      info.forEach(e => { infoIX[e.name] = e })
      echarts.parseGeoJSON(russia3dgeo).forEach(r => {
        if (infoIX[r.name]) {
          let c = r.getCenter();
          info3d.push([
            c[0], c[1], infoIX[r.name].value
          ])
        }
      })

      serie = {
        type: 'bar3D',
        coordinateSystem: 'geo3D',
        shading: 'lambert',
        data: info3d,
        barSize: 0.5,
        minHeight: 0.2,
        silent: true,
        itemStyle: {
          color: 'orange'
          // opacity: 0.8
        }
      }
    } else {
      serie =  {
        name: '',
        type: 'map',
        map,
        projection,
        //roam: true, //zoom+pan
        emphasis: {
          label: {
            show: false
          }
        },
        select: {
          label: {
            show: false
          }
        },
        data: info
      }
    }

    this.chart = echarts.init(this.element)

    let opts = {
      title: {
        text: '',
      },
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        formatter: function (el) {
          var value = el.value;
          return [
            '<div class="tooltip-title">' +
            echarts.format.encodeHTML(el.data.name) +
            '</div>',
            '<strong>' + (value == '-' ? value : formatNumber(value)) + '</strong>' +
            `${el.data.r2 ? ` (R<sup>2</sup> = ${el.data.r2})` : ''}`
          ].join('');
        }
      },
      visualMap: {
        left: 'right',
        min: minValue,
        max: maxValue,
        inRange: {
          color: palette
        },
        //text: ['High', 'Low'],
        calculable: true
      },
      geo3D,
      series: [serie]
    };

    this.chart.setOption(opts)
  }

  disconnect() {
    this.chart.dispose()
  }

  layout() {
    this.chart.resize()
  }
}
