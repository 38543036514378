// ~ Dispatch jQuery events as regular DOM events ~
//
// Delegated events are given a new name in the format `jquery:<original event name>`.
// If you delegate `ajax:send` you will be able to listen for `jquery:ajax:send`
// on native event listeners such as Stimulus actions and `EventTarget.addEventListener`.
//
// Notes:
//   * The first parameter must be called "event".
//   * The parameters can be accessed as members on the `event.detail` object.
//
// Example:
//   delegate 'ajax:send', parameters: ['event', 'xhr']
//   document.addEventListener 'jquery:ajax:send', (event) -> console.log(event.detail)
//
// Ref: https://gist.github.com/kaspermeyer/7fe28bb7c55c2810e7b5f3d5e67c1a44

import $ from 'jquery'

const delegate = function(eventName, {parameters, as, fallback}) {
  const handler = function(...args) {
    const nativeEventName = as || `jquery:${eventName}`;
    const data = {};
    for (let index = 0; index < parameters.length; index++) { const name = parameters[index]; data[name] = args[index]; }
    const delegatedEvent = new CustomEvent(nativeEventName, {
      bubbles: true,
      cancelable: true,
      detail: data
    });
    return data.event.target.dispatchEvent(delegatedEvent);
  };
  if (fallback) {
    document.addEventListener(eventName, handler);
  } else {
    $(document).on(eventName, handler);
  }
};

// jQuery UJS
//
// Source: https://github.com/rails/jquery-ujs/wiki/ajax
//
//delegate 'ajax:before',           parameters: ['event']
//delegate 'ajax:beforeSend',       parameters: ['event', 'xhr', 'settings']
//delegate 'ajax:send',             parameters: ['event', 'xhr']
//delegate 'ajax:success',          parameters: ['event', 'data', 'status', 'xhr']
//delegate 'ajax:error',            parameters: ['event', 'xhr', 'status', 'error']
//delegate 'ajax:complete',         parameters: ['event', 'xhr', 'status']
//delegate 'ajax:aborted:required', parameters: ['event', 'elements']
//delegate 'ajax:aborted:file',     parameters: ['event', 'elements']


// Bootstrap (v4.1)
//
// Source: https://getbootstrap.com/docs/4.1/getting-started/javascript/#events
//
// ~ alert
delegate('close.bs.alert',        {parameters: ['event'], fallback: true});
delegate('closed.bs.alert',       {parameters: ['event'], fallback: true});
// ~ carousel
delegate('slide.bs.carousel',     {parameters: ['event'], fallback: true});
delegate('slid.bs.carousel',      {parameters: ['event'], fallback: true});
// ~ collapse
delegate('show.bs.collapse',      {parameters: ['event'], fallback: true});
delegate('shown.bs.collapse',     {parameters: ['event'], fallback: true});
delegate('hide.bs.collapse',      {parameters: ['event'], fallback: true});
delegate('hidden.bs.collapse',    {parameters: ['event'], fallback: true});
// ~ dropdown
delegate('show.bs.dropdown',      {parameters: ['event'], fallback: true});
delegate('shown.bs.dropdown',     {parameters: ['event'], fallback: true});
delegate('hide.bs.dropdown',      {parameters: ['event'], fallback: true});
delegate('hidden.bs.dropdown',    {parameters: ['event'], fallback: true});
// ~ tab
delegate('show.bs.tab',           {parameters: ['event'], fallback: true});
delegate('shown.bs.tab',          {parameters: ['event'], fallback: true});
delegate('hide.bs.tab',           {parameters: ['event'], fallback: true});
delegate('hidden.bs.tab',         {parameters: ['event'], fallback: true});
// ~ modal
delegate('show.bs.modal',         {parameters: ['event'], fallback: true});
delegate('shown.bs.modal',        {parameters: ['event'], fallback: true});
delegate('hide.bs.modal',         {parameters: ['event'], fallback: true});
delegate('hidden.bs.modal',       {parameters: ['event'], fallback: true});
// ~ popover
delegate('show.bs.popover',       {parameters: ['event'], fallback: true});
delegate('shown.bs.popover',      {parameters: ['event'], fallback: true});
delegate('hide.bs.popover',       {parameters: ['event'], fallback: true});
delegate('hidden.bs.popover',     {parameters: ['event'], fallback: true});
delegate('inserted.bs.popover',   {parameters: ['event'], fallback: true});
// ~ scrollspy
delegate('activate.bs.scrollspy', {parameters: ['event'], fallback: true});
// ~ tooltip
delegate('show.bs.tooltip',       {parameters: ['event'], fallback: true});
delegate('shown.bs.tooltip',      {parameters: ['event'], fallback: true});
delegate('hide.bs.tooltip',       {parameters: ['event'], fallback: true});
delegate('hidden.bs.tooltip',     {parameters: ['event'], fallback: true});
delegate('inserted.bs.tooltip',   {parameters: ['event'], fallback: true});

// Select 2 (v4.0)
//
// Source: https://select2.org/programmatic-control/events
//
delegate('select2:closing',     {parameters: ['event']});
delegate('select2:close',       {parameters: ['event']});
delegate('select2:opening',     {parameters: ['event']});
delegate('select2:open',        {parameters: ['event']});
delegate('select2:selecting',   {parameters: ['event']});
delegate('select2:select',      {parameters: ['event']});
delegate('select2:unselecting', {parameters: ['event']});
delegate('select2:unselect',    {parameters: ['event']});
