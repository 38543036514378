import { Controller } from "stimulus"
import $ from "jquery"

export default class extends Controller {
  connect() {
    this.toggleVocRegion(this.data.has('role') && this.data.get('role') === '3')
    this.toggleOrganization(this.data.has('role') && this.data.get('role') === '4')

    let accessSections = this.data.has('accessSections') ? (JSON.parse(this.data.get('accessSections')) || []) : [];
    this.toggleMicroorganismsRights(
      accessSections.includes('microorganisms')
    )
  }

  doRoleSelect(event) {
    const roleId = event.target.value
    this.toggleVocRegion(
      roleId === '3'
    )
    this.toggleOrganization(
      roleId === '4'
    )
  }

  doAccessSectionSelect(event) {
    let moSection = $('#access_sections_block input[type=checkbox][value=microorganisms]:checked').length;

    this.toggleMicroorganismsRights(
      moSection === 1
    )
  }

  toggleVocRegion(isReg) {
    if (isReg) {
      $('#voc_region').show()
    } else {
      $('#voc_region').hide()
    }
  }

  toggleOrganization(isOrg) {
    if (isOrg) {
      $('#organization').show()
    } else {
      $('#organization').hide()
    }
  }

  toggleMicroorganismsRights(hasMicro) {
    if (hasMicro) {
      $('#microorganisms_rights_block').show()
    } else {
      $('#microorganisms_rights_block').hide()
    }
  }
}
