import { Controller } from "stimulus"
import $ from "jquery"
import { getCellAt, scanTable } from "mz_ui/utils/table_matrix"

export default class extends Controller {
  arrow = {
    enter: 13,
    left: 37,
    up: 38,
    right: 39,
    down: 40
  }

  inputSelector = 'input,textarea,select'

  connect() {
    this.cellsMatrix = scanTable(this.element);

    $(this.element).on('keydown.table-keynav', this.inputSelector, this._handleKey.bind(this));
  }

  disconnect() {
    $(this.element).off('.table-keynav');
  }

  _handleKey(e) {
    // shortcut for key other than arrow keys
    if ($.inArray(e.which, [this.arrow.left, this.arrow.up, this.arrow.right, this.arrow.down, this.arrow.enter]) < 0) {
      return;
    }

    var input = e.target;
    var td = $(e.target).closest('td,th');
    var moveTo = null;

    switch (e.which) {
      case this.arrow.left: {
        if (input.selectionStart === 0) {
          moveTo = td.prevAll('td:has(' + this.inputSelector + '):first,th:has(' + this.inputSelector + '):first');
        }
        break;
      }

      case this.arrow.right: {
        if (input.selectionEnd === input.value.length) {
          moveTo = td.nextAll('td:has(' + this.inputSelector + '):first,th:has(' + this.inputSelector + '):first');

          if (moveTo.length === 0) { // last col on 'right' arrow - move to next row first cell
            var tr = td.closest('tr');
            var nextTr = tr.nextAll('tr:has(' + this.inputSelector + '):first');
            moveTo = nextTr.find('td:has(' + this.inputSelector + '):first,th:has(' + this.inputSelector + '):first');
          }
        }
        break;
      }

      case this.arrow.up:
      case this.arrow.down:
      case this.arrow.enter: {
        var pos = td.data('cellPos');
        var moveToCell = null;

        if (e.which === this.arrow.down || e.which === this.arrow.enter) {
          var offset = 1;

          moveToCell = getCellAt(this.cellsMatrix, pos.left, pos.top + offset);

          while (moveToCell && (moveToCell.is(td) || !moveToCell.is(':has(' + this.inputSelector + ')'))) {
            offset++;
            moveToCell = getCellAt(this.cellsMatrix, pos.left, pos.top + offset);
          }
        } else if (e.which === this.arrow.up) {
          var offset = 1;

          moveToCell = getCellAt(this.cellsMatrix, pos.left, pos.top - offset);

          while (moveToCell && (moveToCell.is(td) || !moveToCell.is(':has(' + this.inputSelector + ')'))) {
            offset++;
            moveToCell = getCellAt(this.cellsMatrix, pos.left, pos.top - offset);
          }
        }

        if (moveToCell) {
          moveTo = moveToCell;
        }

        break;
      }
    }

    if (moveTo && moveTo.length) {
      e.preventDefault();

      moveTo.find(this.inputSelector).each(function (i, input) {
        input.focus();
        input.select();
      });
    }
  }
}
