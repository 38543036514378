import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex'
import $ from "jquery"

export default class extends Controller {
  connect() {
    StimulusReflex.register(this)
  }
  loadPathologies(event) {
    let id = $(event.target).val()
    if (!id) { return }

    $('#microorganisms-report-pathologies-details select').attr('data-controller', '')

    this.stimulate('Microorganisms::Report#pathologies_details', { serializeForm: false }, id)
  }

  loadPathologyKinds(event) {
    let id = $(event.target).val()
    if (!id) { return }

    $('#microorganisms-report-pathology-kinds-details select').attr('data-controller', '')

    this.stimulate('Microorganisms::Report#pathology_kinds_details', { serializeForm: false }, id)
  }
}
