import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex'
import $ from "jquery"

export default class extends Controller {
  static targets = [ "subTypes"]

  connect() {
    if (!this.data.has('dangerTypeId') || !this.data.get('dangerTypeId')) {
      $(this.subTypesTarget).hide()
    }
    StimulusReflex.register(this)
  }

  loadDanderSubTypes(event) {
    let id = $(event.target).val();
    if (id) {
      $(this.subTypesTarget).show();
    } else {
      $(this.subTypesTarget).hide();
    }

    this.stimulate('Integration::BiologicalDanger::Report#danger_sub_types_details', { serializeForm: false }, id)
  }
}
