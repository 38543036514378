import { Controller } from "stimulus"

import * as echarts from 'echarts';

import { formatNumber } from 'number-format';


export default class extends Controller {
  connect() {
    let labels = JSON.parse(this.data.get('labels')) || []
    let colors = JSON.parse(this.data.get('colors')) || []
    let data = JSON.parse(this.data.get('data')) || []
    let datasetLabels = JSON.parse(this.data.get('datasetLabels')) || []
    let radius = JSON.parse(this.data.get('radius')) || '80%'

    let title = this.data.get('title')
    let type = this.data.get('type')
    let suggestedMax = this.data.get('suggestedMax')
    let suggestedMin = this.data.get('suggestedMin')
    let max = this.data.get('max')
    let min = this.data.get('min')
    let precision = this.data.get('precision')

    let datasets = []



    data.forEach((el, ix) => {
      let ds = {
        name: datasetLabels[ix],
        type: 'pie',
        radius: radius,
        color: colors,
        label: {
          show: false
        },
        data: el,
        tooltip: {
          valueFormatter: formatNumber
        },
        itemStyle : {
          normal: {
            label: {
              show: true,
              position: 'inner',
              color: 'black',
              fontSize: 14,
              formatter: function (params) {
                return params.value + '%\n'
              },
            },
            labelLine: {
              show: false
            }
          },
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }

      datasets.push(ds)
    })

    let opts = {
      animation: false,
      legend: {
        orient: 'vertical',
        left: 'left'
      },
      toolbox: {
        feature: {
          saveAsImage: {
            name: title || 'График',
            title: 'Сохранить изображение графика'
          }
        }
      },
      title: {
        left: 'center',
        text: title,
        textStyle: {
          fontSize: 12,
          overflow: 'break'
        }
      },
      tooltip: {
        trigger: 'item',
        formatter: (params) => {
          return `${params.seriesName}<br> ${params.marker} ${params.name}` //<br> <b>${params.data.add_value}`
        }
      },
      series: datasets
    }

    //setTimeout( () => {
      this.chart = echarts.init(this.element)
      this.chart.setOption(opts)
    //}, 75)

  }

  disconnect() {
    this.chart.dispose()
  }

  layout() {
    this.chart.resize()
  }
}
