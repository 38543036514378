import { Controller } from "stimulus"
import $ from "jquery"
import "select2"
import "mz_ui/components/select2_mz_ru"
import "mz_ui/components/select2_multi_controls"

import { fire, matches } from "@rails/ujs"
import templateFn from "mz_ui/utils/template"
import $$ from "mz_ui/utils/dom"

export default class extends Controller {
  connect() {
    let ajax, dropdownParent, minimumInputLength, multiple, placeholder, templateResult, dropdownAdapter, width, tags;
    const autocomplete = this.data.get('autocomplete');
    const template = this.data.get('templateResult');
    const minimumResultsForSearch = this.data.get('minimumResultsForSearch');

    if (this.data.has('multiple')) {
      multiple = this.data.get('multiple');
    } else if (this.element.hasAttribute('multiple')) {
      multiple = true;
    } else {
      multiple = false;
    }

    if (this.data.has('tags')) {
      tags = this.data.get('tags');
    } else {
      tags = false;
    }

    if (this.data.has('width')) {
      width = this.data.get('width');
    } else {
      width = '100%';
    }

    if (this.data.has('placeholder')) {
      placeholder = this.data.get('placeholder');
    } else if (this.element.hasAttribute('placeholder')) {
      placeholder = this.element.getAttribute('placeholder');
    }

    if (this.data.has('dropdownParent')) {
      dropdownParent = $(this.data.get('dropdownParent'));
    } else if (this.data.has('inlineFilter') || this.data.has('filter')) {
      const modal = $(this.element).parents('div.modal:first');
      dropdownParent = modal.length > 0 ? modal : $('body');
    } else {
      dropdownParent = $(this.element).parent('div');
    }

    if (this.data.has('multiControls')) {
      dropdownAdapter = $.fn.select2.amd.require("MultiControlsDropdownAdapter")
    }

    if (autocomplete) {
      minimumInputLength = this.data.get('minimumInputLength') || 4
      const elData = this.data

      ajax = {
        url: this.data.get('autocompleteUrl'),
        dataType: 'json',
        quietMillis: 500,
        data(params) {
          const autocompleteParams = elData.get('autocompleteParams')
          let autocompleteParamsParsed = {}
          try { autocompleteParamsParsed = JSON.parse(autocompleteParams) } catch (e) { }
          return Object.assign({ search: params.term, page: params.page || 1 }, autocompleteParamsParsed)
        },
        processResults(data, params) {
          params.page = params.page || 1
          return {
            results: data.results,
            pagination: {
              more: (params.page * 10) < data.count_filtered
            }
          }
        }
      }
    } else {
      minimumInputLength = this.data.get('minimumInputLength') || null
    }

    if (template) {
      const t = templateFn($$(template).innerHTML);
      templateResult = function(result) {
        if (!result.id) { return result.text }
        return $(t(result))
      };
    }

    const $element = $(this.element);

    $element.select2({
      theme: 'bootstrap-5',
      width,
      tags,
      dropdownParent,
      language: 'ru',
      minimumInputLength,
      multiple,
      ajax,
      placeholder,
      templateResult,
      dropdownAdapter,
      minimumResultsForSearch
    });

    if (this.data.has('inlineFilter')) {
      const $s2 = $element.data('select2');
      $s2.$container.addClass('select2-mz-inline-filter');
      $s2.$dropdown.addClass('select2-mz-inline-filter');

      // dirty hack on ugly select2 events
      const elMemo = this.element;

      $s2.on('open', () => $element.data('__select2.preSelected', JSON.stringify($element.val())));
      $s2.on('close', function() {
        if ($element.data('__select2.preSelected') !== JSON.stringify($element.val())) {
          fire(elMemo, 'inline-filter:change');
        }
        $element.data('__select2.preSelected', null);
      });
    }

    if (matches(this.element, '.is-invalid')) {
      $element.data('select2').$container.addClass('is-invalid');
    }
  }

  disconnect() {
    if ($(this.element).data('select2')) {
      $(this.element).select2('destroy');
    }
  }
}
