import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex'
import $ from 'jquery'

export default class extends Controller {
  static targets = [ "regionId", "otherRegionLabel", 'emergencyDt', 'measureFields']

  connect() {
    StimulusReflex.register(this)

    this.toggleRegionFields(
      this.data.has('voc-country-id') && (
        this.data.get('voc-country-id') === '185'
      )
    )
    this.toggleMeasureFields(
      this.data.has('voc-measure-id') && (
        this.data.get('voc-measure-id') != ''
      )
    )

    this.toggleEmergencyDt(
      this.data.has('voc-emergency-id') && (
        this.data.get('voc-emergency-id') != ''
      )
    )
  }

  doCountrySelect(event) {
    const country_id = event.target.value
    this.toggleRegionFields(country_id === '185')
  }

  doEmergencySelect(event) {
    const emergency_id = event.target.value
    this.toggleEmergencyDt(emergency_id != '')
  }

  doMeasuresSelect(event) {
    let checked = false;
    $(event.currentTarget).find("option:selected").each(function(i, selected){
      checked = true
    });
    this.toggleMeasureFields(checked)
  }

  toggleRegionFields(isRegion) {
    if (!this.hasRegionIdTarget && !!this.hasOtherRegionLabelTarget) { return }

    if (isRegion) {
      $(this.regionIdTarget).show()
      $(this.otherRegionLabelTarget).hide()
    } else {
      $(this.regionIdTarget).hide()
      $(this.otherRegionLabelTarget).show()
    }
  }

  toggleMeasureFields(isMeasure) {
    if (!this.hasMeasureFieldsTarget) { return }

    if (isMeasure) {
      $(this.measureFieldsTarget).show()
    } else {
      $(this.measureFieldsTarget).hide()
    }
  }

  toggleEmergencyDt(isEmerg) {
    if (!this.hasEmergencyDtTarget) { return }

    if (isEmerg) {
      $(this.emergencyDtTarget).show()
    } else {
      $(this.emergencyDtTarget).hide()
    }
  }
}
