import {Controller} from "stimulus"

export default class extends Controller {
  connect() {
    this.moved()
  }

  moved() {
    const selector = '.js-move-item';

    let ww = window.screen.width;

    document.querySelectorAll(selector).forEach(e => {
      const to = e.dataset.to,
        from = e.dataset.from,
        point = e.dataset.point;

      if (ww < point) {
        document.querySelector(to).appendChild(e)
        e.classList.add("is-moved")
      } else {
        if (e.classList.contains('is-moved')) {
          document.querySelector(from).appendChild(e)
          e.classList.remove("is-moved")
        }
      }
    })
  }

}
