import { Controller } from "stimulus"
import $ from "jquery"

export default class extends Controller {
  static targets = [ "input", "info" ]

  connect() {
    this.toggleInfo()
  }

  toggleInfo(event) {
    if ($(this.inputTarget).find('input[type=radio]:checked').val() > 0) {
      $(this.infoTarget).removeClass('d-none')
    } else {
      $(this.infoTarget).addClass('d-none')
    }
  }
}
