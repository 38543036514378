import { Controller } from "stimulus"

// Проверка поддержки WEBP, добавление класса webp или no-webp для HTML
export default class extends Controller {
  connect() {
    // Добавление класса _webp или _no-webp для HTML
    this.testWebP((support) => {
      const className = support === true ? 'webp' : 'no-webp'
      document.documentElement.classList.add(className)
    })
  }

  // Проверка поддержки webp
  testWebP(callback) {
    const webP = new Image()
    // eslint-disable-next-line no-multi-assign
    webP.onload = webP.onerror = () => {
      callback(webP.height === 2)
    }
    // eslint-disable-next-line max-len
    webP.src = "data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA"
  }
}
