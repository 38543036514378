import { Controller } from "stimulus"
import $ from "jquery"

export default class extends Controller {
  connect() {
    this.toggleVocInstitution(this.data.has('orgRole') && this.data.get('orgRole') === 'mo')
  }

  doRoleSelect(event) {
    const roleId = event.target.value
    this.toggleVocInstitution(
      roleId === 'mo'
    )
  }

  toggleVocInstitution(isMo) {
    if (isMo) {
      $('#voc_institution').show()
    } else {
      $('#voc_institution').hide()
    }
  }
}
