import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex'
import $ from 'jquery'

export default class extends Controller {
  static targets = [ "filterDiseases", "filterRegions" ]

  connect() {
    StimulusReflex.register(this)

  }

  changeSituationType(event) {
    let id = $(event.target).val()
    this.loadSituations(id)
    this.loadDiseases(id)
    this.toggleFilterDiseases((id != '' && id < 4))
  }

  changeCountry(event) {
    let id = $(event.target).val()
    this.loadRegions(id)
    this.toggleFilterRegions(id != '')
  }

  loadSituations(situationType) {
    $('#incidents-cards-filter-situations-details select').attr('data-controller', '')

    this.stimulate('Incidents::Card#filter_situations_details', { serializeForm: false }, situationType)
  }

  loadDiseases(situationType) {
    $('#incidents-cards-filter-diseases-details select').attr('data-controller', '')

    this.stimulate('Incidents::Card#filter_diseases_details', { serializeForm: false }, situationType)
  }

  loadRegions(country) {
    $('#incidents-cards-filter-regions-details select').attr('data-controller', '')

    this.stimulate('Incidents::Card#filter_regions_details', { serializeForm: false }, country)
  }

  toggleFilterDiseases(isDisease) {
    if (!this.hasFilterDiseasesTarget) { return }

    if (isDisease) {
      $(this.filterDiseasesTarget).removeClass('d-none')
    } else {
      $(this.filterDiseasesTarget).addClass('d-none')
    }
  }

  toggleFilterRegions(isReg) {
    if (!this.hasFilterRegionsTarget) { return }

    if (isReg) {
      $(this.filterRegionsTarget).removeClass('d-none')
    } else {
      $(this.filterRegionsTarget).addClass('d-none')
    }
  }
}
