import { Controller } from "stimulus"
import html2canvas from 'html2canvas'
import jsPDF  from "jspdf"

export default class Html2pdf extends Controller {
  downloadPdf(event) {
    const { pdfLabel } = event.currentTarget.dataset;
    const printArea = document.getElementById('print-area');

    // let isNeedRemoveScrollClass = false;
    //
    // if (printArea.classList.contains('scroll-column')) {
    //   printArea.classList.remove('scroll-column');
    //   isNeedRemoveScrollClass = true;
    // }

    html2canvas(printArea).then(function(canvas) {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: "landscape"
      });

      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, (pdfHeight - 20));
      pdf.save(`${pdfLabel}.pdf`);
    })

    // if (isNeedRemoveScrollClass) {
    //   printArea.classList.add('scroll-column');
    // }
  }
}
