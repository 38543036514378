// Import TinyMCE
import tinymce from 'tinymce/tinymce'
import 'tinymce-i18n/langs5/ru'
import 'tinymce-i18n/langs5/ru'

import { Controller } from 'stimulus'
export default class extends Controller {
  static targets = ['input']

  initialize () {
    this.defaults = {
      base_url: process.env.ASSET_PATH + 'tinymce',
      plugins:
        'print preview paste importcss autolink autosave save directionality code visualblocks visualchars fullscreen image link media table charmap hr pagebreak nonbreaking anchor toc advlist lists imagetools textpattern noneditable help charmap quickbars',
      menubar: 'file edit view insert format tools table',
      toolbar:
        'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist preview | forecolor backcolor removeformat | pagebreak | charmap | insertfile image media link anchor | ltr rtl fullscreen',
      toolbar_sticky: true,
      suffix: '.min',
      language: 'ru'
      //skin: (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'oxide-dark' : 'oxide'),
      //content_css: (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'default')
    }
  }

  connect () {
    // Initialize the app
    let config = Object.assign({ target: this.inputTarget }, this.defaults)
    tinymce.init(config)
  }

  disconnect () {
    tinymce.remove()
  }
}

