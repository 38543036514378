import { Controller } from "stimulus"

import * as echarts from 'echarts';

import { formatNumber } from 'number-format';

import regression from 'regression';
import { round, determinationCoefficient } from 'math-utils';


export default class extends Controller {
  connect() {
    let labels = JSON.parse(this.data.get('labels')) || []
    let colors = JSON.parse(this.data.get('colors')) || []
    let data = JSON.parse(this.data.get('data')) || []
    let datasetLabels = JSON.parse(this.data.get('datasetLabels')) || []

    let title = this.data.get('title')
    let type = this.data.get('type')
    let trendline = this.data.get('trendline')
    let suggestedMax = this.data.get('suggestedMax')
    let suggestedMin = this.data.get('suggestedMin')
    let max = this.data.get('max')
    //let min = this.data.get('min')
    let precision = this.data.get('precision')
    let regrType = this.data.get('trendlineRegression') || 'linear'
    let skip_zoom_slider = this.data.get('skipZoomSlider') || false
    let label_rotate = this.data.get('labelRotate') || 0
    let fill_area = this.data.get('fillArea') || false
    let yType = this.data.get('yType') || 'value'
    let legendRight = this.data.get('legendRight') || 'auto'

    let min = yType == 'log' ? 1 : undefined

    let datasets = []

    data.forEach((el, ix) => {
      let ds = {
        name: datasetLabels[ix],
        type: 'line',
        itemStyle: {
          color: colors[ix]
        },
        data: el,
        lineStyle: { color: colors[ix] },
        tooltip: {
          valueFormatter: formatNumber
        },
      }
      if (fill_area) { ds.areaStyle = {}  }

      datasets.push(ds)

      if (trendline) {
        let regrData = []
        for (let i = 0; i < el.length; i++) {
          regrData.push([i, (+el[i]) || 0])
        }
        let regr = regression[regrType](regrData)
        let calcRegrData = [];
        for (let i = 0; i < el.length; i++) { // Fill the array with calculated values
          calcRegrData.push(regr.points[i][1]);
        }

        datasets.push({
          name: regr.r2 ? `${datasetLabels[ix]} - тренд (R2 = ${regr.r2})` : `${datasetLabels[ix]} - тренд (R2 = ${round(determinationCoefficient(regrData, regr.points), 2)})`,
          type: 'line',
          data: calcRegrData,
          tooltip: {
            valueFormatter: formatNumber
          },
          color: '#000',
          lineStyle: {
            width: 1,
          },
          showSymbol: false,
        })
      }
    })


    let opts = {
      animation: false,
      legend: {
        right: legendRight
      },
      title: {
        text: title
      },
      toolbox: {
        feature: {
          saveAsImage: {
            name: title || 'График',
            title: 'Сохранить изображение графика'
          }
        }
      },
      tooltip: {
        trigger: 'axis',
      },
      xAxis: {
        data: labels,
        type: 'category',
        axisLabel: {
          rotate: label_rotate
        },
        axisTick: {
          alignWithLabel: true
        }
      },
      yAxis: {
        type: yType,
        min: min,
        axisLabel: {
          formatter: formatNumber
        },
        axisPointer: {
          label: {
            formatter: formatNumber
          }
        }
      },
      series: datasets,
      dataZoom: [
        {
          type: 'inside'
        },
        {
          type: 'slider',
          show: !skip_zoom_slider
        }
      ]
    }

    //setTimeout( () => {
      this.chart = echarts.init(this.element)
      this.chart.setOption(opts)
    //}, 75)
  }

  disconnect() {
    this.chart.dispose()
  }

  layout() {
    this.chart.resize()
  }
}
