import { Controller } from "stimulus"
import * as echarts from 'echarts';

import { formatNumber } from 'number-format';

import $ from "jquery"

export default class extends Controller {
  connect() {

    let labels = JSON.parse(this.data.get('labels')) || []
    let colors = JSON.parse(this.data.get('colors')) || []
    let data = JSON.parse(this.data.get('data')) || []
    let title = this.data.get('title')
    let subtitle = this.data.get('subtitle')
    let data_label_formatter = this.data.get('dataLabelFormatter') || '{c}'

    let datasetLabels = JSON.parse(this.data.get('datasetLabels'))

    let datasets = []

    data.forEach((el, ix) => {
      let ds = {
        name: datasetLabels[ix],
        type: 'bar',
        data: el,
        color: colors[ix],
        label: {
          show: true,
          formatter: (params) => params.data.value + '%' + '\n\n' + params.data.cnt,
          // formatter: data_label_formatter,
          color: 'black'
        },
        tooltip: {
          // formatter: '666'
          valueFormatter: (value) => value + '%'
        }
      }

      datasets.push(ds)
    })

    this.chart = echarts.init(this.element)

    let opts = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      title: {
        subtext: subtitle,
        left: 'left',
        subtextStyle: {
          fontWeight: 'bold'
        }
      },
      toolbox: {
        feature: {
          saveAsImage: {
            name: title || 'График',
            title: 'Сохранить изображение графика'
          }
        }
      },
      legend: {},
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'value',
        axisLabel: {
          formatter: "{value}%"
        }
      },
      yAxis: {
        type: 'category',
        data: labels
      },
      series: datasets
    }

    this.chart.setOption(opts)
  }

  disconnect() {
    this.chart.dispose()
  }

  layout() {
    this.chart.resize()
  }
}
