import { Controller } from "stimulus"
import { fire } from '@rails/ujs'

export default class extends Controller {
  notifySavedSuccess(e) {
    fire(this.element, 'form-constructor:saved:success')
  }

  notifySavedError(e) {
    fire(this.element, 'form-constructor:saved:error')
  }
}
