import { Controller } from "stimulus"
import $ from "jquery"
import { getCellAt, scanTable } from "mz_ui/utils/table_matrix"

export default class extends Controller {
  connect() {
    this.cellsMatrix = scanTable(this.element);

    this.element.addEventListener('paste', this._handlePaste.bind(this), false);
  }

  disconnect() {
    this.element.removeEventListener('paste', this._handlePaste.bind(this), false);
  }

  _handlePaste(event) {
    let paste = (event.clipboardData || window.clipboardData).getData('text');
    let pos = $(event.target).closest('td,th').data('cellPos')
    let rows = paste.split(String.fromCharCode(10))

    for (let i = 0;i < rows.length;i++) {
      if (!rows[i]) continue;
      let cells = rows[i].split(String.fromCharCode(9))

      for (let j = 0;j < cells.length;j++) {
        let cell = getCellAt(this.cellsMatrix, pos.left + j, pos.top + i)
        if (cell) {
          var new_input = $(cell).find('input,textarea')

          if (new_input) {
            new_input.val(cells[j])
          }
        }
      }
    }

    event.preventDefault();
  }
}
